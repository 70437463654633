
import { getStoreInfoList, getStoreInfo, getMapList } from '@/api/storeInfo';
import { getMapStayList, getStay, getStayList } from '@/api/stay';
import {
  getLat,
  getLng,
  setLat,
  setLng,
} from '@/utils/cookies';
import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import Pagination from '@/components/Pagination/index.vue';

@Component({
  components: {
    Pagination,
  },
})

export default class extends Vue {
  async mounted() {
    await this.setUserLocation();
    this.initKakao();
  }

  private setUserLocation() {
    const lat = getLat();
    const lng = getLng();
    if (lat && lng) {
      this.listQuery.lat = lat;
      this.listQuery.lng = lng;
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        this.listQuery.lat = position.coords.latitude;
        this.listQuery.lng = position.coords.longitude;
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
      });
    }
  }

  private initKakao() {
    if ((window as any).kakao && (window as any).kakao.maps) {
      this.initMap(this.listQuery.lat, this.listQuery.lng);
    }
  }

  private map = null;

  private storeInfo = {
    name: '',
    introduce: '',
    tags: [],
    address: '',
    addressDetail: '',
    lat: '',
    lng: '',
    inquiryTel: '',
    basicInfo: '',
    operationTimeInfo: '',
    equipmentInfo: '',
    parkingInfo: '',
    locationInfo: '',
    reserveUrl: '',
    distance: '',
    images: [],
    companyName: '',
    bossName: '',
    companyAddress: '',
    companyAddressDetail: '',
    profileFileUid: '',
    greeting: '',
  }

  private handlelayoutVisible = false;

  private selectedItem = '';

  private loading = true;

  private drawerLoading = true;

  private overlayList: any = [];

  private listQuery: any = {
    lat: '',
    lng: '',
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
    // 1 숙박
    // 2 체험 //3 레저
    // 4 외식
    // 5 일반상품
    // 6 관광
    industryIdx: 1,
    // map 외식, 관광, 레저, 체험 조회시 필요
    useDate: moment().format('YYYY-MM-DD'),
    // list 조회시 필요
    searchValue: '',
    page: 0,
    size: 8,
    sort: 'distance',
    region: '',
    adult: 0,
    child: 0,
    startPrice: 0,
    endPrice: 1000000,
    categories: '',
    options: '',
  }

  private apiUrl = process.env.VUE_APP_COMMON_API;

  private clusterer: any = null;

  private async initMap(lat: any, lng: any) {
    const container = document.getElementById('map');
    const option = {
      center: new (window as any).kakao.maps.LatLng(lat, lng),
      level: 6,
    };
    this.map = new (window as any).kakao.maps.Map(container, option);
    this.clusterer = new (window as any).kakao.maps.MarkerClusterer({
      map: this.map,
      averageCenter: true,
      minLevel: 4,
    });

    const markerPosition = new (window as any).kakao.maps.LatLng(lat, lng);
    const imageOption = { offset: new (window as any).kakao.maps.Point(30, 30) };
    const marker = new (window as any).kakao.maps.Marker({
      position: markerPosition,
      image: new (window as any).kakao.maps.MarkerImage(
        '/img/map__currentlocation_marker.svg',
        new (window as any).kakao.maps.Size(60, 60),
        imageOption,
      ),
    });
    marker.setMap(this.map);
    // marker.panTo(markerPosition);
    this.getPlaceList();
  }

  private getPlaceList() {
    this.loading = true;
    // this.overlayList.forEach((overlay: any) => overlay.setMap(null));
    this.overlayList = [];
    if (this.listQuery.industryIdx === 1) {
      getMapStayList(this.listQuery).then(async (res) => {
        this.overlayList = res.data;
        await this.mapMarker();
        this.loading = false;
      }).catch(() => {
        this.$message.error('숙소를 불러오는데 실패했습니다.');
        this.loading = false;
      });
    } else {
      getMapList(this.listQuery).then(async (res) => {
        this.overlayList = res.data;
        await this.mapMarker();
        this.loading = false;
      }).catch(() => {
        this.$message.error('정보를 불러오는데 실패했습니다.');
        this.loading = false;
      });
    }
  }

  private async mapMarker() {
    const overlayList: any = await this.createOverlays();
    // this.overlayList.forEach((overlay: any) => overlay.setMap(null));
    const customOverlayList: any = [];
    overlayList.forEach((overlay: any) => {
      customOverlayList.push(overlay.overlay);
    });
    await this.clusterer.addMarkers(customOverlayList);
  }

  private async createOverlays() {
    /* eslint-disable */
    return new Promise((resolve: any) => {
      if (this.clusterer) this.clusterer.clear();
      const overlayList: any = [];
      this.overlayList.forEach((stay: any) => {
        if (stay.lat && stay.lng) {
          let content = this.createOverlayContent(stay);
          const overlay = new (window as any).kakao.maps.CustomOverlay({
            map: this.map,
            content: content,
            yAnchor: 1.5,
            position: new (window as any).kakao.maps.LatLng(Number(stay.lat), Number(stay.lng)),
            clickable: true,
          });
          overlayList.push({
            idx: stay.idx,
            overlay: overlay,
          });
          content.addEventListener('mouseup', () => { this.handleSelectItem(stay.idx) });
        }
      });
      this.overlayList = overlayList;
      resolve(overlayList);
    });
    /* eslint-enable */
  }

  private createOverlayContent(stay: any) {
  /*     const content = document.createElement('div');
    content.innerHTML = `<div class="balloon">
      <span class="right"></span>
      <span class="center">${name}</span>&nbsp;
      </div>`;
 */
    const content = document.createElement('div');
    content.innerHTML = `
    <div class="balloon">
      <span class="right"></span>
      <span class="center">${stay.name}</span>
      </div>
    `;
    return content;
  }

  private handleSelectItem(idx: any) {
    this.drawerLoading = true;
    this.handlelayoutVisible = !this.handlelayoutVisible;
    if (this.listQuery.industryIdx === 1) {
      getStay(idx, this.listQuery).then((res) => {
        this.selectedItem = res.data;
        this.drawerLoading = false;
      });
    } else {
      getStoreInfo(idx, this.listQuery).then((res) => {
        this.selectedItem = res.data;
        this.drawerLoading = false;
      });
    }
  }

  private toggleActive(val: number) {
    if (this.listQuery.industryIdx !== val) {
      this.listQuery.industryIdx = val;
      this.getPlaceList();
    }
  }

  /* 현재 위치 정보 */
  private userMapInstance() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          this.initMap(lat, lng);
        },
        (error) => {
          console.error('현재위치정보를 가져오는 중 오류가 발생하였습니다.', error);
        },
      );
    } else {
      console.error('예기치 못한 오류가 발생하였습니다.');
    }
  }

  private markerList() {
    this.getStayList();
    this.handleListVisible = !this.handleListVisible;
  }

  private handleItemClick(idx: any) {
    if (this.listQuery.industryIdx === 1) { // 숙박
      this.$router.push({
        name: 'StayDetail',
        params: { stayIdx: idx },
        query: {
          startDate: this.listQuery.startDate,
          endDate: this.listQuery.endDate,
          adult: '2',
          child: this.listQuery.child,
        },
      });
    } else if (this.listQuery.industryIdx === 2) { // 체험
      this.$router.push({ name: 'ExcitingView', params: { idx } });
    } else if (this.listQuery.industryIdx === 3) { // 레저
      this.$router.push({ name: 'LeisureView', params: { idx } });
    } else if (this.listQuery.industryIdx === 4) { // 외식
      this.$router.push({ name: 'RestaurantView', params: { idx } });
    } else if (this.listQuery.industryIdx === 5) { // 일반상품
      this.$router.push({ name: 'GoodsView', params: { uid: idx } });
    } else if (this.listQuery.industryIdx === 6) { // 관광
      this.$router.push({ name: 'PlaceView', params: { idx } });
    }
  }

  private handleListVisible = false;

  private stayList = [];

  private getStayList() {
    this.drawerLoading = true;
    if (this.listQuery.industryIdx === 1) {
      getStayList(this.listQuery).then((res) => {
        this.drawerLoading = false;
        this.stayList = res.data.content;
        this.totalElements = res.data.totalElements;
      }).catch(() => {
        this.$message.error('숙소를 불러오는데 실패했습니다.');
        this.drawerLoading = false;
      });
    } else {
      getStoreInfoList(this.listQuery).then((res) => {
        this.drawerLoading = false;
        this.stayList = res.data.content;
        this.totalElements = res.data.totalElements;
      }).catch(() => {
        this.$message.error('숙소를 불러오는데 실패했습니다.');
        this.drawerLoading = false;
      });
    }
  }

  private totalElements = 0;

  private distanceType(distance: number | string): number {
    const value = Number(distance);
    if (value > 1000) {
      return value / 1000;
    }
    return 0;
  }
}
